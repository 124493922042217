@import '../../assets/css/responsive';

div.filters.filters-container {
  margin: 40px auto 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: none;
    ul.filter-list > li,
    div.helpi-button-container {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        input,
        button,
        & {
          border-radius: 24px;
        }
      }
  ul.filter-list {
    justify-content: center;
    display: flex;
    height: 35px;
    li {
      min-width: 190px;
      margin-left: 4px;
      input {
        background-color: #fff;
        border-radius: 24px;
        padding-right: 8px;
        padding-left: 20px;
      }
      div.input-wrapper-container {
        > div[data-react-toolbox="input"] {
          padding: 0;
          span {
            display: none;
          }
          label {
            top: 9px;
          }
          input {
            font-size: 16px;
            &:focus {
              ~ label {
                display: none;
              }
            }
          }
        }
      }
      div.select-container,
      div.multiple-select-container,
      div.input-wrapper-container {
        color: #000;
        height: 100%;
        //height: 30px;
        font-size: 12px;
        border: none;
        width: 100%;
        > div.has-value {
          label {
            display: none;
          }
        }
        > div.filter-dropdown {
          height: 100%;
          width: 100%;
          > div {
            padding: 0;
            &::after {
              border-top-color: #000;
            }
          }
        }
        > *,
        > * > *,
        >* > * > * {
          font-family: 'Fb Reforma';
          font-size: 12px;
          height: 100%;
        }
        label {
          color: #000;
          top: 12px;
          right: 8px;
        }
        ul {
          padding: 10px 0;
          overflow-y: scroll;
          height: auto;
        }
      }
    }
  }
  div.filter-dropdown {
    width: 190px;
  }
}

@media (max-width: 1025px) and (orientation: landscape) {
  div.filters.filters-container {
    ul.filter-list {
      li {
        input {
          margin-top: 0;
        }
      }
    }
  }
}

@media (max-width: $layout-breakpoint-sm) {
  div.filters.filters-container {
    flex-flow: column nowrap;
    ul.filter-list {
      flex-flow: column nowrap;
      height: auto;
      li {
        height: 35px;
        margin-bottom: 15px;
        margin-left: 0;
        input {
          margin-top: 0;
        }
        &:last-child {
          width: 194px;
        }
      }
    }
    button.filter-search-btn{
      &:focus{
        outline: none;
      }
    }
  }
}