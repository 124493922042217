@import "../../assets/css/colors";
@import "../../assets/css/responsive";

//  the new style


.group-leader-message {
  display: flex;
  justify-content: center;

  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    margin: 0 0 2em 0;
    padding: 1em;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

    a {
      cursor: pointer;
      color: var(--primary-color);
    }
  }

}



.corner-img {
  position: absolute;
  top: 0;
  right: -100px;
  z-index: -1;
  width: 400px;
  height: 400px;

  // border: 1px solid red;
  img {
    width: 100%;
    height: 100%;
  }


}

.case-container {
  display: flex;
  margin: 10% 2%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3%;

  .img-container {
    margin-left: 5%;
    width: clamp(350px, 30%, 539px);

    .content-box-img {
      max-width: 521px;
      max-height: 394px;
      margin: auto;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        background-size: cover;
      }
    }


    .volunteer-list-container {
      margin-left: 5%;
      height: 100%;
      width: 100%;
    }
  }



  .text-container {
    width: clamp(500px, 32%, 650px);
    // border: red 1px solid;
    font-size: 20px;
    line-height: 1.3;

    .title {
      width: 80%;

      p {
        font-weight: bold;
        margin-top: -10px;
        padding-top: 0px;
        font-family: 'Fb Reforma', 'arial';
        font-size: 30px;
        color: $secondary-color;
        text-align: right;
      }
    }

    .case-description {
      width: 90%;

      // border: 1px solid rgb(0, 255, 149);
      p {
        font-family: 'Fb Reforma', 'arial';
        color: $text-color;
        text-align: right;
      }
    }

    .task-item {
      .task-detail {
        .text-emphasized {
          color: var(--secondary-color);

        }
      }

      .separator {
        display: block;

        img {
          width: 90%;
          height: 1px;
        }
      }

      .helpi-button-container {
        button {
          display: flex;
          align-items: center;
          gap: 1.7rem;
          padding: 1.3rem 1.5rem;
          border-radius: 1.3rem;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }
      }
    }

    a {
      cursor: pointer;
      color: var(--primary-color);
    }

    .conditions-text {
      margin-top: 25px;
      font-weight: 400;
    }

    .task-description {
      ul {
        // how i make the space between lines smaller
        list-style-type: disc;
        li{
          margin-right: 32px;
          padding-right: 9px;  
        }
        li::marker {
          color: $secondary-color;
          font-size: 30px;
        }
      }
    }
  }



  .social-buttons {
    .edit-icon {
      .material-icons {
        margin: 0;
      }

      i {
        margin: 5px;
        padding: 2px;
        background: rgb(255, 224, 178);
        border-radius: 50%;
        color: #FFF;
      }
    }

    div.SocialMediaShareButton {
      &.SocialMediaShareButton--whatsapp {
        display: none;
      }
    }

  }


}
@include media($layout-breakpoint-md, $layout-breakpoint-md + 100px) {
  .case-container{
  justify-content: left;
  margin-left: 2%;
  .img-container {
    margin-bottom: 2%;
  }
 }
}
@include media($layout-breakpoint-sm, $layout-breakpoint-md) {
    .corner-img{
      display: none;
    }
}
@include media-max($layout-breakpoint-sm) {
  .corner-img {
    display: none;
  }

  .case-container {
    flex-direction: column;
    align-items: center;
    .img-container {

      .content-box-img {
        display: none;
      }

      order: 2;
    }

    .text-container {
      order: 1;
    }

    .social-buttons {
      order: 3;
      display: inline-flex;
      gap: 10px;

    }
  }
}



// the old style 
// .case-detail-view {
//   div.group-leader-message {
//     display: flex;
//     justify-content: center;
//     div.message {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       font-size: 17px;
//       margin: 0 0 2em 0;
//       padding: 1em;
//       box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
//       a {
//         cursor: pointer;
//         color: #1ABCC5;
//       }
//     }

// .card-container {
//   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
// }
// .delete-volunteer {
//   cursor: pointer;
//   font-size: 14px;
//   margin-right: 5px;
// }

// .add-volunteer {
//   cursor: pointer;
//   color: #1ABCC5;
// }

// .volunteer-list-container {
//   margin-top: 1.5em;
//   padding: 8px;
//   h3{
//     margin-bottom: 0.5em;
//   }
// }

//   }
//   .card-container {
//     box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
//   }
//   .delete-volunteer {
//     cursor: pointer;
//     font-size: 14px;
//     margin-right: 5px;
//   }

//   .add-volunteer {
//     cursor: pointer;
//     color: #1ABCC5;
//   }

//   div.volunteer-list-container {
//     margin-top: 1.5em;
//     padding: 8px;
//     h3{
//       margin-bottom: 0.5em;
//     }
//   }

//   div.case-header-panel {
//     background-color: rgb(241, 244, 244);
//     position: relative;
//     display: flex;
//     justify-content: space-between;
//     .content-box-img {
//       height: 200px;
//       width: 30%;
//       img {
//         height: 100%;
//         width: 100%;
//         background-size: cover;
//       }
//       flex: 1 0 auto !important; // safari fix
//     }

//     .content-box-text {
//       min-height: 200px;
//       //max-height: 300px;
//       //width: 100%;
//       padding: 25px;
//       flex: 1 0 auto !important; //safari fix
//       display: flex;
//       align-items: flex-end;
//       width: 70%;
//       box-sizing: border-box;
//       //position: relative;
//       h1 {
//         font-size: 22px;
//         width: 100%;
//         margin-top: 20px;
//       }
//       div.company-data {
//         position: absolute;
//         bottom: 0;
//         right: 0;
//         padding: 0;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         img {
//           max-width: 200px;
//           height: 60px;
//           width: auto;
//           object-fit: cover;
//           padding-left: 1em;
//         }
//         span {
//           font-size: 20px;
//         }
//       }
//     }

//   }
//   .centered-bubble {
//     /* PLEASE NOTE - container must be position:relative */
//     margin: auto;
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     height: 50px;
//     width: 50px;
//     opacity: 0.8;
//   }
//   .case-information {
//     padding: 25px;
//     font-size: 15px;
//   }
//   h4 {
//     max-width: calc(100% - 80px);
//   }
//   .tasks-list-title {
//     margin-bottom: 15px;
//   }
//   .task-item {
//     h5 {
//       font-size: 17px;
//     }
//     a {
//       color: var(--primary-color);
//     }
//     .conditions-text {
//       margin-top: 5px;
//       font-weight: 400;
//     }
//     .task-title-row {
//       margin-bottom: 7px;
//     }
//     .task-title {
//       padding-left: 60px;
//     }
//     .text-emphasized {
//       color: var(--secondary-color);
//     }

//     .task-detail {
//       &.current-user-volunteer {
//         > span:not(.text-emphasized) {
//           font-weight: 600;
//         }
//       }
//     }

//     .helpi-button-container{
//       .volunteer-btn {
//         //float: right;
//         height: 40px;
//         margin-top: 40px;
//         padding: 1px 10px;
//       }
//     }

//   }

// }

// .locations-section {
//   text-align: right;
// }

// .picker {
//   float: right;
//   position: relative;
//   top: -14px;
// }

// .buttons-section {
//   margin: auto !important;

//   .buttons-wrapper {
//     margin: auto !important;
//   }
// }

// .log-section {
//   text-align: right;
// }

// .contactUs {
//   a {
//     color: var(--primary-color);
//   }
// }

// @include media-max($layout-breakpoint-sm) {
//   .case-detail-view {
//     div.case-header-panel {
//       h1 {
//         font-size: 16px;
//         height: 100%;
//       }
//       .social-buttons {
//         position: absolute;
//         top: 7px;
//         left: 7px;
//         div.SocialMediaShareButton.SocialMediaShareButton--whatsapp {
//           display: block;
//         }

//       }
//       .content-box-img {
//         min-height: 100px;
//       }
//       .content-box-text {
//         min-height: 70px;
//         margin-top: 70px;
//         padding: 10px;
//         .title-box {

//         }
//       }
//     }

//   }
// }

// @include media-max($layout-breakpoint-xs) {
//   .case-detail-view {
//     div.group-leader-message {
//       max-width: 100%;
//       div.message {
//        text-align: center;
//       }
//     }
//     div.volunteer-list-container {
//       margin-top: 1.5em;
//       padding: 8px;
//       max-width: 100%;
//       table {
//         tbody tr {
//           td {
//             max-width: 30vw;
//             overflow-y: scroll;
//           }
//         }
//       }
//       h3{
//         margin-bottom: 0.5em;
//       }
//     }
//     div.case-header-panel {
//       .content-box-img {
//         display: none;
//       }
//     }
//   }
// }